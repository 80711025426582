.div{
    background-color: #274a62;
    height: 100%;
    padding: 3rem;
    box-sizing: border-box;
}

.div h2{
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
}

.content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.methods{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    margin-left: 1rem;
}

.method{
    width: 50%;
}

.method img{
    width: 3.75rem;
}

.method h3{
    color: white;
    font-size: 1.25rem;
    font-weight: 350;
    letter-spacing: 0.1rem;
    margin: 0.5rem 0; 
}

.method p{
    margin: 0;
}

.method p a{
    color: white;
    text-decoration: none;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0.1rem;
}

.method p a:hover{
    text-decoration: underline;
}

@media (max-width: 721px){

    .methods{
        height: 100%;
        margin-left: 0;
    }

    .method{
        width: 100%;
        margin: 2rem;
    }
}

