.div{
    height: calc(100vh - 5rem);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content section{
    width: 100%;
    height: calc(100vh - 5rem);
}


@media (max-width: 1025px){
    .div{
        height: fit-content;
    }

    .content{
        flex-direction: column;
    }
}


@media (max-width: 721px){
    .div{
        padding-top: 5rem;
        box-sizing: border-box;
    }

    .content section{
        height: fit-content;
    }
}