.div{
    height: 100vh;
    width: 100%;
}

.transparency{
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.6);
}

.banner1{
    content: "";
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('./img/1.png') no-repeat center center fixed;
    background-size: cover;
    z-index: -1;
    text-align: right;
}

.banner2{
    content: "";
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('./img/3.jpg') no-repeat center center fixed;
    background-size: cover;
    z-index: -1;
    text-align: left;
}

.banner3{
    content: "";
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('./img/2.jpg') no-repeat center center fixed;
    background-size: cover;
    z-index: -1;
    text-align: right;
    transform: scaleX(-1);
}

.banner3 .transparency{
    
    transform: scaleX(-1);
}

.content{
    width: 85%;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.elements{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.lines{
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
}

@keyframes growHorizontal {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.active .horizontal{
    background-color: #274a62;
    width: 100%;
    height: 4px;
    animation: growHorizontal 3s forwards;
}

.vertical{
    background-color: #274a62;
    width: 4px;
    height: 9rem;
}

.text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.title{
    color: #274a62;
    font-size: 3.5rem;
    margin: 0;
    font-weight: 400;
    font-style: italic;
    line-height: 4.5rem;
    letter-spacing: 0.1rem;
    height: 9rem;
}

.resume{
    width: 80%;
    color: #343434;
    letter-spacing: 0.05rem;
}

.banner2 .text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

@keyframes growHorizontalReverse {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

.active .space{
    height: 100%;
    background-color: red;
    animation: growHorizontalReverse 2s forwards;
}

.banner2 .horizontal{
    animation: none;
}

@media (max-width: 721px) {
    
    .content{
        width: 87%;
    }
    
    .title{
        height: fit-content;
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
    
    .resume{
        width: 100%;
    }
}