.div{
    height: fit-content;
    display: flex;
}

.div section{
    padding-top: 9rem;
    box-sizing: border-box;
    width: 50%;
    padding-bottom: 4rem;
}

.title{
    height: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 3.5rem;
}

.div section h2{
    font-size: 1.5rem;
    color: white;
    letter-spacing: 0.2rem;
    font-weight: 600;
    margin: 0 1rem;
    display: inline;
}

@keyframes draw-line {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.line {
    height: 0.2rem;
    background-color: #d4d4d4;
    width: 0;
    opacity: 0;
}

.line1{
    height: 0.2rem;
    background-color: white;
    width: 100%;
}


.line.animate {
    animation: draw-line 2s forwards;
    opacity: 1;
}

.div section h3{
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: #bdbdbd;
    margin-bottom: 0.5rem;
}

.content{
    width: 85%;
}

.profileImg{
    width: 25rem;
    border: #d4d4d4 solid 4px;
}

.profileImg img{
    width: 100%;
    display: block;
}

.content p{
    color: #343434;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 2rem;
    margin: 0;
}

.catala{
    text-align: right;
    background-color: #d4d4d4;
}

.catala .content{
    margin-left: auto;
    box-sizing: border-box;
}

.catala .content .title .line{
    background-color: white;
}

.catala .content .withMargin{
    padding-right: 3rem;
}

.catala .profileImg{
    margin-left: auto;
    border-color: white;
    border-radius: 8rem 0 0 8rem;
}

.catala .profileImg img{
    border-radius: 8rem 0 0 8rem;
}

.catala .content h3{
    color: white;
}

.hanseryk{
    text-align: left;
}

.hanseryk .content{
    box-sizing: border-box;
}

.hanseryk .content .withMargin{
    padding-left: 3rem;
}

@media (max-width: 1025px){
    .profileImg{
        width: 100%;
    }
}


@media (max-width: 721px){
    .div{
        display: block;
        height: fit-content;
        width: 100%;
        background-color: #d4d4d4;
    }

    .div section{
        width: 100%;
        padding-bottom: 0;
    }

    .content{
        width: 85%;
        margin: 0 auto;
        padding: 4rem 0;
    }

    .div .content h2{
        font-size: 1.5rem;
        color: white;
        letter-spacing: 0.2rem;
        font-weight: 600;
        margin: 0 1rem;
        display: inline;
    }

    .line{
        background-color: white;
    }

    .title{
        margin-bottom: 0;
    }

    .div .content section{
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        text-align: left;
    }

    .div .content section.hanseryk{
        align-items: end;
        text-align: right;
    }

    .catala .profileImg{
        border-radius: 0;
        margin-left: 0;
    }

    .catala .profileImg img{
        border-radius: 0;
    }

    .div .content h3{
        color: white;
    }

    .profileImg{
        width: 100%;
        border: white solid 4px;
    }
}