.div{
    background-color: #274a62;
    height: calc(100vh + 4rem);
}

.content{
    width: 85%;
    margin: 0 auto;
    padding: 8rem 0 6rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
}

.content section{
    width: 30%;
    margin-bottom: 2rem;
    box-sizing: border-box;
    padding: 0.5rem;
    height: 13rem;
}
@keyframes draw-border-top {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes draw-border-left {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title::before,
.title::after {
    content: '';
    position: absolute;
    background-color: #d4d4d4;
    opacity: 0;
}

.title.animate::before {
    top: 0;
    right: 0;
    width: 100%;
    height: 2px;
    animation: draw-border-top 2s forwards;
    opacity: 1;
}

.title.animate::after {
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    animation: draw-border-left 2s forwards;
    opacity: 1;
}
.title h2{
    color:#d4d4d4;
    font-size: 2rem;
    letter-spacing: 0.5rem;
}

.service img{
    width: 4rem;
}

.service h3{
    color: white;
    letter-spacing: 0.15rem;
    font-weight: 400;
}

.service h3.bold{
    font-weight: 600;
}

.service p{
    color: white;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    font-weight: 300;
}

@media (max-width: 1025px){

    .div{
        height: fit-content;
    }
    
    .content section{
        width: 50%;
    }

    .title{
        height: 15rem !important;
    }
}


@media (max-width: 721px){

    .content section{
        width: 100%;
        margin-top: 2rem;
    }

    .title{
        height: 10rem !important;
        margin-top: 0 !important;
    }

}