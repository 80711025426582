.div{
    padding: 3rem 3rem 0 3rem;
    height: 100%;
    display: flex;
    justify-content: end;
    box-sizing: border-box;
}

.div .content{
    width: 75%;
    margin-right: 3rem;
}

.content h2{
    color: #274a62;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin-bottom: 2.5rem;
}

.content form{
    text-align: left;
}

.label{
    color: #343434;
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
    letter-spacing: 0.05rem;
}

.input{
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}

.input input{
    width: 100%;
    height: 2rem;
    padding: .50rem;
    padding-right: 0;
    box-sizing: border-box;
    border: none;
    border-bottom: solid 1px #343434;
}

.input input::placeholder{
    opacity: 50%;
}

.input textarea{
    width: 100%;
    height: 4rem;
    resize: vertical;
    padding: .50rem;
    padding-right: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-bottom: solid 1px #343434;
}

.error{
    color: #EE675C;
    margin-top: -20px;
    font-size: 14px;
}

form button{
    color: #274a62;
    border: solid 2px #274a62;
    background-color: white;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-weight: 400;
    padding: 0.5rem 1.75rem;
}

form button:hover{
    color: white;
    background-color: #274a62;
}

form button:disabled{
    cursor: not-allowed;

    opacity: 0.5;
}

.formButton{
    text-align: right;
}

@media (max-width: 721px){

    .div{
        margin-bottom: 3rem;
        padding: 0;
        padding-top: 3rem;
        justify-content: center;
    }

    .div .content{
        width: 85%;
        margin: 0 auto;
    }

    form button{
        font-size: 14px;
        letter-spacing: 0.1rem;
        font-weight: 400;
        border-radius: 5px;
        padding: 0.75rem 2.5rem;
    }

}