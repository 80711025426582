.NavLink{
    color: #343434;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    margin-left: 2rem;
    letter-spacing: 0.05rem;
}

.dot{
    color: transparent;
}

.NavLink:hover{
    color: #3b6d70;
}

.selected{
    color: #3b6d70;
}

.selected .dot{
    color: #3b6d70;
}

@media (max-width: 721px){
    .div{
        width: 100%;
        background-color: white;
        border-top: #343434 solid 1.5px;
    }

    .NavLink{
        display: block;
        margin-left: 0;
        height: 3rem;
        line-height: 3.5rem;
        padding-bottom: none;
    }

    .NavLink:hover, .selected{
        border: none;
        background-color: rgba(0, 0, 0, 0.03);
    }
}