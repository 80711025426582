.div{
    background-color: #d4d4d4;
    height: 7rem;
    display: flex;
    align-items: center;
}

.content{
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location p {
    display: flex;
    align-items: center;
    color: #343434;
    font-weight: 600;
    font-size: 0.9rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0;
}

.location p:hover{
    text-decoration: underline;
}

.location p img {
    display: inline-block;
    vertical-align: middle;
    width: 1.25rem;
    margin-right: 0.5rem;
}

.location p a {
    display: inline-block;
    text-decoration: none;
    color: #343434;
}

.social a{
    margin-left: 0.75rem;
}

.social a img{
    height: 2.25rem;
}

@media (max-width: 721px){

    .div{
        padding: 1rem 0;
    }

    .content{
        flex-direction: column;
    }

    .location{
        margin-bottom: 1rem;
    }
}