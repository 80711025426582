.div {
    height: 5rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.home {
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
}

.scrolledHome {
    background-color: rgba(255,255,255,0.9);
    position: fixed;
    top: 0;
    width: 100%;
}

.services, .contact {
    background-color: white;
    border-bottom: #343434 solid 2.5px;
}

.content {
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

h1 {
    margin: 0;
    height: 3rem;
}

h1 img {
    height: 100%;
}

.nav {
    margin-left: auto;
}

@media (max-width: 721px){
    
    .div{
        background-color: rgba(255,255,255,0.9);
        position: fixed;
        top: 0;
        width: 100%;
        display: block;
    }

    .content h1{
        margin-top: 0.75rem;
    }

    .content{
        width: 90%;
    }

    .mobileMenu{
        line-height: 75px;
        height: 1.5rem;
        width: 2rem;
        margin-top: 0.25rem;
        margin-left: auto;
        margin-right: 0;
        padding-top: 1.7rem;
        padding-bottom: 1.30rem;
    }

    .bar{
        width: 100%;
        height: 0.1rem;
        border-radius: 1px;
        margin-bottom: 0.6rem;
        background-color: #3b6d70;
    }

    .nav{
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

}
