.div{
    height: calc(100vh - 12rem - 2.5px);
    background-color: #274a62;
    display: flex;
    align-items: center;
}

.content{
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    border-left: solid 1px white;
    padding: 1rem 0;
    box-sizing: border-box;
}

.menuItem {
    border: none;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    padding-left: 2rem;
    margin: 0.25rem 0;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

.menuItem:hover {
    background-color: #1b3544;
}

.menuItem p{
    color: white;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
    margin: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.menuItem img{
    width: 2.5rem;
    margin-right: 1rem;
}

.active {
    background-color: white;

}

.active p{
    color: #274a62;
}

.active:hover{
    background-color: white;
}

.container {
    flex: 1;
    padding: 5rem 0 5rem 5rem;
    overflow: hidden;
    position: relative;
}

.containerDiv{
    color: white;
    padding-right: 2rem;
    font-weight: 300;
    letter-spacing: 0.05rem;
    line-height: 2rem;
    margin: 0;
    overflow-y: auto;
    max-height: calc(100vh - 12rem - 2.5px - 10rem);
    text-align: left;
}

.containerDiv::-webkit-scrollbar {
    width: 8px;
}

.containerDiv::-webkit-scrollbar-thumb {
    background-color: #3b6d70;
    border-radius: 10px;
}

.containerDiv::-webkit-scrollbar-track {
    background-color: #1e3c4c;
    border-radius: 10px;
}

.containerDiv ol, .containerDiv ol li{
    text-align: left;
}

h2{
    color: white;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
    margin: 2rem 0 0;
}

.container h3{
    color: white;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
}

@media (max-width: 721px){
    .div{
        height: auto;
        min-height: calc(100vh - 12rem - 2.5px);
    }

    .content{
        flex-direction: column;
        justify-content: center;
        margin-top: 6rem !important;
        height: auto;
    }

    .sidebar {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px white;
        border-left: none;

    }

    .menuItem {
        padding: 0.5rem !important;
        margin: 0;
        border-radius: 100%;
    }

    .menuItem:hover {
        background-color: #1b3544;
    }
    
    .menuItem p{
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }

    .menuItem img{
        width: 2.5rem;
        margin-right: 0;
    }

    .active {
        background-color: white;
    
    }

    .container {
        flex: 1;
        padding: 2rem 0 2rem 1rem;
        overflow: visible !important;
        position: relative;
        height: auto !important;
    }

    .containerDiv p{
        color: white;
        padding-right: 1rem;
        font-weight: 300;
        letter-spacing: 0.05rem;
        line-height: 2rem;
        margin: 0;
        overflow-y:visible;
        max-height:fit-content;
    }

    .containerDiv .selectedItemTitle{
        font-weight: 600;
        letter-spacing: 0.1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .containerDiv{
        text-align:justify;
    }
}